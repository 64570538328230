import * as React from 'react';

function Section0({ pageData }) {
  const title = pageData.roadMapHeader;
  // const roadMapUrl = pageData.roadMap.file.url;
  return (
    <section className="w-full mobile:hidden mobile:px-[15px]">
      <div
        className="
                xl:w-[1250px]
                lg:w-[980px]
                mx-auto
            "
      >
        <h2
          className="
                    relative
                    xl:top-[35px]
                    lg:top-0
                    lg:mb-[44px]
                    text-rubik
                    text-40
                    font-bold
                    leading-[65px]
                    text-purple
                    mt-[50px]
                    xl:ml-[100px]
                    lg:ml-[50px]
                "
        >
          {title}
        </h2>
        {/* <img src={roadMapUrl} alt={title} /> */}
      </div>
    </section>
  );
}

export default Section0;
